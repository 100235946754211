import { render, staticRenderFns } from "./StatisticsTime.vue?vue&type=template&id=120bd376&scoped=true&"
import script from "./StatisticsTime.vue?vue&type=script&lang=js&"
export * from "./StatisticsTime.vue?vue&type=script&lang=js&"
import style0 from "./StatisticsTime.vue?vue&type=style&index=0&id=120bd376&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "120bd376",
  null
  
)

export default component.exports