<template>
  <div class="workbench">
    <div class="breadcrumb">
      <el-breadcrumb>
        <el-breadcrumb-item>首页</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content container">
      <!-- <div>
        <img src="@/assets/workbench_new.png" alt="">
        <p>首页正在建设中</p>
      </div> -->
      <!-- 首页看板 -->
      <div class="maincontent">
        <!-- 左边 -->
        <div class="c_left">
          <!-- 左边头部 -->
          <div class="c_left_head">
            <div class="l_head_item a" @click="goToPage('1')">
              <div>{{ mainInfo.dfk || 0 }}</div>
              <div class="h_i_text">{{ "待付款" }}</div>
            </div>
            <div class="l_head_item b" @click="goToPage('2')">
              <div>{{ mainInfo.dfh || 0 }}</div>
              <div class="h_i_text">{{ "待发货" }}</div>
            </div>
            <div class="l_head_item c" @click="goToPage('c')">
              <div>{{ mainInfo.dsh || 0 }}</div>
              <div class="h_i_text">{{ "待售后处理" }}</div>
            </div>
            <div class="l_head_item d" @click="goToPage('4')">
              <div>{{ mainInfo.ywc || 0 }}</div>
              <div class="h_i_text">{{ "已完成" }}</div>
            </div>
          </div>
          <!-- 左边主要 -->
          <div class="c_left_main">
            <div class="mian_time_box">
              <div>
                <StatisticsTime
                  @timeList="timeList"
                  :prop-time="'今日实时'"
                  :needall-time="false"
                ></StatisticsTime>
              </div>
            </div>
            <div class="data_all">
              <div class="data_head">
                <div>数据总览</div>
                <div class="out" @click="exportData">
                  <div>
                    <img src="../../assets/home/导出.svg" />
                  </div>
                  <div>
                    导出
                  </div>
                </div>
              </div>
              <div class="pay_info_box">
                <div class="p_info_item">
                  <div class="p_info_title">{{ "支付买家数" }}</div>
                  <div class="p_info_numb">{{ mainInfo.user_count || 0 }}</div>
                </div>
                <div class="p_info_item">
                  <div class="p_info_title">{{ "支付件数" }}</div>
                  <div class="p_info_numb">{{ mainInfo.num || 0 }}</div>
                </div>
                <div class="p_info_item">
                  <div class="p_info_title">{{ "支付金额（元）" }}</div>
                  <div class="p_info_numb">
                    {{ mainInfo.sum_pay_money || 0 }}
                  </div>
                </div>
                <div class="p_info_item">
                  <div class="p_info_title">{{ "平均客单价" }}</div>
                  <div class="p_info_numb">{{ mainInfo.kdj || 0 }}</div>
                </div>
                <div class="p_info_item">
                  <div class="p_info_title">{{ "退款金额（元）" }}</div>
                  <div class="p_info_numb">
                    {{ mainInfo.refund_price || 0 }}
                  </div>
                </div>
                <div class="p_info_item">
                  <div class="p_info_title">{{ "出售中商品数" }}</div>
                  <div class="p_info_numb">{{ mainInfo.goods_count || 0 }}</div>
                </div>
              </div>
            </div>
            <div class="info_table">
              <div class="table_title">
                <div>商品销量榜</div>
                <div class="title_more" @click="goToOverView">查看更多>></div>
              </div>
              <div class="table_box">
                <el-table
                  ref="multipleTable"
                  :data="tableData"
                  tooltip-effect="dark"
                  stripe
                  :header-cell-style="{
                    background: '#ebebfd',
                    color: '#333333',
                  }"
                >
                  <el-table-column label="排名" width="80">
                    <template slot-scope="scope">
                      <div v-if="scope.row.index == 1">
                        <img src="../../assets/home/vip1.svg" />
                      </div>
                      <div v-if="scope.row.index == 2">
                        <img src="../../assets/home/vip2.svg" />
                      </div>
                      <div v-if="scope.row.index == 3">
                        <img src="../../assets/home/vip3.svg" />
                      </div>
                      <div v-if="scope.row.index > 3" class="index_box">
                        {{ scope.row.index }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="商品" min-width="250">
                    <template slot-scope="scope">
                      <div>
                        <span>{{ scope.row.goods_name }}</span
                        ><span v-if="scope.row.sku_name"
                          >【{{ scope.row.sku_name }}】</span
                        >
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="支付件数"
                    prop="num"
                    min-width="100"
                  ></el-table-column>
                  <el-table-column
                    label="支付金额(元)"
                    prop="sum_pay_money"
                    min-width="100"
                  ></el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
        <!-- 右边 -->
        <div class="c_right">
          <div class="c_right_head">
            <div class="shop_img">
              <img
                v-if="!shopInfo.shop_logo_str"
                src="../../assets/home/shop_img.svg"
                style="width: 58px; height: 58px"
              />
              <img
                v-else
                :src="shopInfo.shop_logo_str"
                style="width: 58px; height: 58px"
              />
            </div>
            <div class="shop_info_box">
              <div class="shop_name">
                <div style="margin-right: 19px">{{ shopInfo.shop_name }}</div>
                <div @click="goToEdit">
                  <img
                    src="../../assets/home/edit.svg"
                    style="cursor: pointer; width: 22px; height: 22px"
                  />
                </div>
              </div>
              <div class="shop_status">
                <div
                  :class="shopInfo.business_status ? 'shop_bg' : 'shop_bg1'"
                  style="margin-right: 4px"
                >
                  {{ shopInfo.business_status ? "营业中" : "已打烊" }}
                </div>
                <div class="shop_bg" v-if="shopInfo.delivery_self">
                  开启自提
                </div>
              </div>
              <div class="evaluate">
                <div style="margin-right:12px;">
                  商品评价：{{ shopInfo.goods_evaluate }}
                </div>
                <div style="margin-right:12px;">
                  商家服务：{{ shopInfo.shop_evaluate }}
                </div>
                <div style="margin-right:12px;">
                  物流服务：{{ shopInfo.logistics_evaluate }}
                </div>
              </div>
            </div>
          </div>
          <div class="c_right_main">
            <div class="c_right_main_title">快捷菜单</div>
            <div class="menu_box">
              <div
                class="menu_item"
                v-for="(item, index) in activeItemList"
                :key="index"
                @click="goToMenu(item.routerPath)"
              >
                <div class="menu_item_box">
                  <div style="cursor: pointer"><img :src="item.img" /></div>
                  <div style="cursor: pointer">{{ item.name }}</div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import home from "@/api/home.js";
import StatisticsTime from "@/components/common/StatisticsTime";
import addGoods from "../../assets/home/addGoods.svg";
import orderList from "../../assets/home/orderList.svg";
import diScount from "../../assets/home/diScount.svg";
import user from "../../assets/home/user.svg";
import ShopService from "../../assets/home/ShopService.svg";
import addOrEditor from "../../assets/home/addOrEditor.svg";
import MarketList from "../../assets/home/MarketList.svg";
export default {
  components: {
    StatisticsTime,
  },
  data() {
    return {
      module_id_array: window.utils.storage.getter("module_id_array") || [],
      // 主要数据
      mainInfo: {},
      // 表格数据
      tableData: [],
      // 店铺信息
      shopInfo: {},
      //菜单列表
      menuList: [
        {
          img: addGoods,
          name: "添加商品",
          routerPath: "/goods/AddGood",
          module_id: "48",
        },
        {
          img: orderList,
          name: "订单管理",
          routerPath: "/order/OrderList",
          module_id: "73",
        },
        {
          img: diScount,
          name: "添加优惠券",
          routerPath: "/discounts/addDiscount",
          module_id: "66",
        },
        {
          img: user,
          name: "用户管理",
          routerPath: "/user/userList",
          module_id: "83",
        },
        {
          img: ShopService,
          name: "店铺客服",
          routerPath: "/operation/shopService/ShopService",
          module_id: "116",
        },
        {
          img: addOrEditor,
          name: "新增活动",
          routerPath: "/operation/activityMgmt/addOrEditor",
          module_id: "103",
        },
        {
          img: MarketList,
          name: "推广商品",
          routerPath: "/market/marketList",
          module_id: "127",
        },
      ],
      order_sort: "4",
      currentTimeList: "", //选择的时间
      page: 1,
      pageNumber: 10,
    };
  },
  computed: {
    activeItemList: function() {
      return this.menuList.filter((item) => {
        return (
          this.module_id_array.includes(item.module_id) ||
          this.module_id_array.includes("999")
        );
      });
    },
  },
  mounted() {
    this.getMainInfo();
    this.getList();
    this.getIndexShopInfo();
  },
  methods: {
    // 获取主要数据
    getMainInfo() {
      const { start, end } = this.currentTimeList;
      const data = {
        create_time_start: start,
        create_time_end: end,
      };
      this.axios.post("/store/shop/getshopdata", data).then((res) => {
        let data = res.data.info;
        this.mainInfo = data;
      });
    },
    // 获取表格数据
    getList() {
      this.tableData = [];
      const { start, end } = this.currentTimeList;
      const data = {
        page: this.page,
        pageNumber: this.pageNumber,
        create_time_start: start, //开始时间
        create_time_end: end, //结束时间
        order_sort: this.order_sort,
      };
      this.axios
        .post("/store/shopgoods/getgoodssurveylist", data)
        .then((res) => {
          let list = res.data.list;
          this.tableData = list.map((res, index) => {
            return {
              index: index + 1,
              ...res,
            };
          });
        });
    },
    // 获取店铺设置
    getIndexShopInfo() {
      home.getIndexShopInfo().then((res) => {
        let { code, data } = res;
        if (code === 0) {
          this.shopInfo = data.info;
        }
      });
    },
    // 导出数据
    exportData() {
      const { start, end } = this.currentTimeList;
      const data = {
        create_time_start: start,
        create_time_end: end,
      };
      console.log("123", data);
      this.axios.post("/store/shop/exportShopData", data).then((res) => {
        let host =
          document.location.protocol +
          "//" +
          window.location.host +
          res.data.url;

        window.open(host);
      });
    },
    // 去订单列表
    goToPage(val) {
      switch (val) {
        case "1":
          if (
            this.module_id_array.includes("73") ||
            this.module_id_array.includes("999")
          ) {
            this.$router.push({
              path: "/order/OrderList",
              query: {
                typeVal: val,
              },
            });
          } else {
            this.$notify.error({
              title: "暂无权限，请联系管理员",
            });
          }

          break;
        case "2":
          if (
            this.module_id_array.includes("73") ||
            this.module_id_array.includes("999")
          ) {
            this.$router.push({
              path: "/order/OrderList",
              query: {
                typeVal: val,
              },
            });
          } else {
            this.$notify.error({
              title: "暂无权限，请联系管理员",
            });
          }
          break;
        case "c":
          if (
            this.module_id_array.includes("138") ||
            this.module_id_array.includes("999")
          ) {
            this.$router.push({
              path: "/afterSales/afterSalesList",
              query: {
                typeVal: "0", //传0过去才会为退款待审核
              },
            });
          } else {
            this.$notify.error({
              title: "暂无权限，请联系管理员",
            });
          }
          break;
        case "4":
          if (
            this.module_id_array.includes("73") ||
            this.module_id_array.includes("999")
          ) {
            this.$router.push({
              path: "/order/OrderList",
              query: {
                typeVal: val,
              },
            });
          } else {
            this.$notify.error({
              title: "暂无权限，请联系管理员",
            });
          }
          break;
        default:
          break;
      }
    },
    //去设置店铺
    goToEdit() {
      if (
        this.module_id_array.includes("105") ||
        this.module_id_array.includes("999")
      ) {
        this.$router.push({
          path: "/system/storeSettings",
        });
      } else {
        this.$notify.error({
          title: "暂无权限，请联系管理员",
        });
      }
    },
    // 回传的时间
    timeList(val) {
      this.currentTimeList = val;
      this.getMainInfo();
      this.getList();
    },
    // 去商品概况
    goToOverView() {
      if (
        this.module_id_array.includes("151") ||
        this.module_id_array.includes("999")
      ) {
        this.$router.push({
          path: "/goods/GoodsOverview",
        });
      } else {
        this.$notify.error({
          title: "暂无权限，请联系管理员",
        });
      }
    },
    indexMethod(index) {
      return index;
    },
    // 快捷菜单
    goToMenu(val) {
      this.$router.push({
        path: val,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.workbench {
  .content {
    margin: 20px;
    overflow: inherit;
    font-size: 14px;
    color: #7f7f7f;
  }
  .container {
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    background-color: transparent;
    box-sizing: border-box;
    overflow: auto;
    .maincontent {
      width: 100%;
      height: 100%;
      display: flex;
      .c_left {
        width: 70%;
        height: 100%;
        margin-right: 10px;
        .c_left_head {
          width: 100%;
          height: 73px;
          margin-bottom: 10px;
          display: flex;
          .l_head_item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            line-height: 24px;
            color: #ffffff;
            cursor: pointer;
            .h_i_text {
              font-size: 12px;
              margin-top: 4px;
              height: 17px;
              line-height: 17px;
            }
          }
          .a {
            width: 25%;
            height: 100%;
            box-sizing: border-box;
            background: url("../../assets/home/head0.png") center center
              no-repeat;
            background-size: 100% 100%;
          }
          .b {
            width: 25%;
            height: 100%;
            box-sizing: border-box;
            margin: 0 10px;
            background: url("../../assets/home/head1.png");
            background-size: 100% 100%;
          }
          .c {
            width: 25%;
            height: 100%;
            margin-right: 10px;
            box-sizing: border-box;

            background: url("../../assets/home/head2.png");
            background-size: 100% 100%;
          }
          .d {
            width: 25%;
            height: 100%;
            box-sizing: border-box;

            background: url("../../assets/home/head3.png");
            background-size: 100% 100%;
          }
        }
        .c_left_main {
          border-radius: 8px;
          width: 100%;
          min-height: calc(100% - 83px);
          background-color: #ffffff;
          .mian_time_box {
            padding: 0 24px;
            display: flex;
            align-items: center;
            height: 53px;
            border-bottom: 1px solid #f0f0f0;
          }
          .data_all {
            padding: 0 24px;
            .data_head {
              margin-top: 8px;
              margin-bottom: 14px;
              font-size: 14px;
              color: #333333;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .out {
                font-size: 14px;
                color: #4974f5;
                cursor: pointer;
                display: flex;
                align-items: center;
                img {
                  margin-right: 4px;
                }
              }
            }
            .pay_info_box {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              .p_info_item {
                width: 32%;
                height: 70px;
                background-color: #ffffff;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border: solid 1px #eeeeee;
                margin-bottom: 15px;
                border-radius: 8px;
                .p_info_title {
                  height: 18px;
                  line-height: 18px;
                  font-size: 12px;
                  color: #4d4d4d;
                  margin-bottom: 4px;
                }
                .p_info_numb {
                  height: 24px;
                  font-size: 20px;
                  line-height: 24px;
                  color: #4d4d4d;
                }
              }
            }
          }
          .info_table {
            padding: 0 24px;
            height: auto;
            .table_title {
              height: 21px;
              font-size: 14px;
              color: #333333;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 8px;
              .title_more {
                height: 21px;
                font-size: 14px;
                color: #4974f5;
                cursor: pointer;
              }
            }
            .table_box {
              .index_box {
                width: 20px;
                height: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }
      .c_right {
        width: 30%;
        height: 100%;
        .c_right_head {
          width: 100%;
          height: 106px;
          margin-bottom: 10px;
          background-color: #ffffff;
          border-radius: 2px;
          padding: 24px 17px;
          box-sizing: border-box;
          display: flex;
          .shop_img {
            margin-right: 20px;
          }
          .shop_info_box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-radius: 2px;
            .shop_name {
              height: 28px;
              font-size: 20px;
              color: #333333;
              display: flex;
              align-items: center;
            }
            .shop_status {
              display: flex;
              align-items: center;
              .shop_bg {
                height: 23px;
                background-color: #e8eeff;
                border-radius: 4px;
                padding: 0 4px;
                color: #4974f5;
              }
              .shop_bg1 {
                height: 23px;
                background-color: #f2f2f2;
                border-radius: 4px;
                padding: 0 4px;
                color: #d4d4d4;
              }
            }
            .evaluate {
              display: flex;
              align-items: center;
            }
          }
        }
        .c_right_main {
          width: 100%;
          min-height: calc(100% - 116px);
          background-color: #ffffff;
          border-radius: 2px;
          padding: 16px;
          box-sizing: border-box;
          .c_right_main_title {
            height: 20px;
            line-height: 20px;
            font-size: 14px;
            color: #333333;
          }
          .menu_box {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .menu_item {
              width: 45%;
              margin-top: 32px;
              .menu_item_box {
                height: 75px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
              }
            }
          }
        }
      }
    }
  }
}
</style>
