import axios from '../service/axios'
export default {
  // 获取店铺信息
  getIndexShopInfo(data) {
    return axios({
      method: 'post',
      url: '/store/Shop/getIndexShopInfo',
      data,
    })
  },
  // 更新店铺logo
  updateShopLogo(data) {
    return axios({
      method: 'post',
      url: '/store/Shop/updateShopLogo',
      data,
    })
  },
  // 更新店铺logo
  updateShopBgimg(data) {
    return axios({
      method: 'post',
      url: '/store/Shop/updateShopBgimg',
      data,
    })
  },
  // 更新店铺营业状态
  updateShopBusinessStatus(data) {
    return axios({
      method: 'post',
      url: '/store/Shop/updateShopBusinessStatus',
      data,
    })
  },
  // 更新权限配置
  getModuleList(data) {
    return axios({
      method: 'post',
      url: '/store/admin/reloadModule',
      data,
    })
  },
}
