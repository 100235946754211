<template>
  <div class="infoMain">
    <div class="time_pick">统计时间:</div>
    <el-select
      v-if="needallTime"
      class="pick"
      v-model="value"
      placeholder="请选择"
      @change="change"
    >
      <el-option
        v-for="item in optionsAll"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <el-select
      v-else
      class="pick"
      v-model="value"
      placeholder="请选择"
      @change="change"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <div class="now_time">
      <div v-if="isTrue">{{ nowdata }}</div>
      <span class="nowtime_text" v-if="value === '今日实时'" @click="onRefresh"
        >刷新</span
      >
    </div>
    <!-- 自然日 -->
    <div v-if="value == '自然日'">
      <el-date-picker
        v-model="valueType"
        type="date"
        :clearable="false"
        placeholder="选择自然日"
        @change="changeData"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
    </div>
    <!-- 自然周 -->
    <div v-if="value == '自然周'">
      <el-date-picker
        v-model="valueType"
        type="week"
        :clearable="false"
        :format="format"
        placeholder="选择自然周"
        :picker-options="pickerOptions"
        @change="changeWeekData"
      >
      </el-date-picker>
    </div>
    <!-- 自然月 -->
    <div v-if="value == '自然月'">
      <el-date-picker
        v-model="valueType"
        type="month"
        :clearable="false"
        placeholder="选择自然月"
        @change="changeData"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
    </div>
    <!-- 自定义时间 -->
    <div v-if="value == '自定义时间'" style="display: block">
      <el-date-picker
        v-model="valueType"
        type="daterange"
        :clearable="false"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="changeData"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    propTime: {
      type: String,
      default: "",
    },
    needallTime: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: [
        {
          value: "今日实时",
          label: "今日实时",
        },
        {
          value: "近七天",
          label: "近七天",
        },
        {
          value: "近30日",
          label: "近30日",
        },
        {
          value: "自然日",
          label: "自然日",
        },
        {
          value: "自然周",
          label: "自然周",
        },
        {
          value: "自然月",
          label: "自然月",
        },
        {
          value: "自定义时间",
          label: "自定义时间",
        },
      ],
      optionsAll:[
        {
          value: "全部",
          label: "全部",
        },
        {
          value: "今日实时",
          label: "今日实时",
        },
        {
          value: "近七天",
          label: "近七天",
        },
        {
          value: "近30日",
          label: "近30日",
        },
        {
          value: "自然日",
          label: "自然日",
        },
        {
          value: "自然周",
          label: "自然周",
        },
        {
          value: "自然月",
          label: "自然月",
        },
        {
          value: "自定义时间",
          label: "自定义时间",
        },
      ],
      value: "",
      isTrue: false,
      nowdata: this.dayjs().format("YYYY-MM-DD HH:mm"),
      valueType: "",
      format: "",
      startTimeStamp: "",
      endTimeStamp: "",
      // 最多选到今天
      pickerOptions: {
        firstDayOfWeek: 1,
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  created() {
    if (this.propTime) {
      this.value = this.propTime;
      this.change();
    }
  },
  methods: {
    change() {
      switch (this.value) {
        case "全部":
          this.valueType = "";
          this.isTrue = false;
          this.emitTime({ start: "", end: "" });
          break;
        case "今日实时":
          this.nowdata = this.dayjs().format("YYYY-MM-DD HH:mm");
          this.isTrue = true;
          const emitTime = {
            start: this.dayjs().format("YYYY-MM-DD"),
            end: this.dayjs().format("YYYY-MM-DD"),
          };
          this.emitTime(emitTime);
          break;
        case "近七天":
          this.nowdata =
            this.dayjs().subtract(8, "day").format("YYYY-MM-DD") +
            " 至 " +
            this.dayjs().subtract(1, "day").format("YYYY-MM-DD");
          this.isTrue = true;
          const emitTime1 = {
            start: this.dayjs().subtract(8, "day").format("YYYY-MM-DD"),
            end: this.dayjs().subtract(1, "day").format("YYYY-MM-DD"),
          };
          this.emitTime(emitTime1);
          break;
        case "近30日":
          const data = this.dayjs().subtract(1, "day").format("YYYY-MM-DD");
          this.nowdata =
            this.dayjs(data).subtract(1, "month").format("YYYY-MM-DD") +
            " 至 " +
            this.dayjs().subtract(1, "day").format("YYYY-MM-DD");
          this.isTrue = true;
          const emitTime2 = {
            start: this.dayjs(data).subtract(1, "month").format("YYYY-MM-DD"),
            end: this.dayjs().subtract(1, "day").format("YYYY-MM-DD"),
          };
          this.emitTime(emitTime2);
          break;
        case "自然日":
          this.valueType = this.dayjs().subtract(1, "day").format("YYYY-MM-DD");
          this.isTrue = false;
          this.emitTime({
            start: this.dayjs().subtract(1, "day").format("YYYY-MM-DD"),
            end: this.dayjs().subtract(1, "day").format("YYYY-MM-DD"),
          });
          break;
        case "自然周":
          this.valueType = this.dayjs();
          this.format =
            this.getFirstLastDay(this.dayjs()).monday +
            " 至 " +
            this.getFirstLastDay(this.dayjs()).sunday;
          this.isTrue = false;
          this.emitTime({
            start: this.getFirstLastDay(this.dayjs()).monday,
            end: this.getFirstLastDay(this.dayjs()).sunday,
          });
          break;
        case "自然月":
          this.valueType = this.dayjs()
            .subtract(1, "month")
            .format("YYYY-MM-DD");
          this.isTrue = false;
          const emitTimeMonth = this.getNowMonth(
            this.dayjs().subtract(1, "month").format("YYYY-MM-DD")
          );
          this.emitTime(emitTimeMonth);
          break;
        case "自定义时间":
          const yestday = this.dayjs().subtract(1, "day");
          this.valueType = [yestday, yestday];
          this.isTrue = false;
          let start = this.valueType[0];
          let end = this.valueType[1];
          const emitTimeMy = {
            start: this.dayjs(start).format("YYYY-MM-DD"),
            end: this.dayjs(end).format("YYYY-MM-DD"),
          };
          this.emitTime(emitTimeMy);
          break;
        case "":
          this.valueType = "";
          this.isTrue = false;
          this.emitTime({ start: "", end: "" });
        default:
          this.valueType = "";
          this.isTrue = false;
        // this.emitTime({start:'',end:''});
      }
    },
    onRefresh() {
      this.nowdata = this.dayjs().format("YYYY-MM-DD HH:mm");
      const emitTime = {
        start: this.dayjs().format("YYYY-MM-DD"),
        end: this.dayjs().format("YYYY-MM-DD"),
      };
      this.emitTime(emitTime);
    },
    changeData(val) {
      this.nowdata = this.valueType;
      if (this.value == "自然月") {
        const emitTime1 = this.getNowMonth(val);
        this.emitTime(emitTime1);
      }
      if (this.value == "自然日") {
        const emitTime = {
          start: this.dayjs(this.nowdata).format("YYYY-MM-DD"),
          end: this.dayjs(this.nowdata).format("YYYY-MM-DD"),
        };
        this.emitTime(emitTime);
      }
      if (this.value === "自定义时间") {
        let start = val[0];
        let end = val[1];
        const emitTime = {
          start: this.dayjs(start).format("YYYY-MM-DD"),
          end: this.dayjs(end).format("YYYY-MM-DD"),
        };
        this.emitTime(emitTime);
      }
    },
    changeWeekData(val) {
      this.startTimeStamp = this.dayjs(val)
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      this.endTimeStamp = this.dayjs(this.startTimeStamp)
        .add(6, "day")
        .format("YYYY-MM-DD");
      this.format = this.startTimeStamp + " 至 " + this.endTimeStamp;
      const emitTime = {
        start: this.startTimeStamp,
        end: this.endTimeStamp,
      };
      this.emitTime(emitTime);
    },
    emitTime(val) {
      this.$emit("timeList", val);
    },
    // 初次获取自然周方法
    getFirstLastDay(time) {
      let date = new Date(time);
      let Time = date.getTime();
      let day = date.getDay();
      let oneDayTime = 24 * 60 * 60 * 1000;

      //周一
      let MondayTime = Time - (day - 1) * oneDayTime;
      //周日
      let SundayTime = Time + (7 - day) * oneDayTime;

      let monday = this.dayjs(new Date(MondayTime)).format("YYYY-MM-DD");
      let sunday = this.dayjs(new Date(new Date(SundayTime))).format(
        "YYYY-MM-DD"
      );
      return { monday, sunday };
    },
    // 获取自然月方法
    getNowMonth(val) {
      let date = new Date(val);
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let year = date.getFullYear();
      let startTime = year + "-" + month + "-" + "01";
      let day = new Date(year, month, 0);
      let endTime = year + "-" + month + "-" + day.getDate();
      const emitTime = {
        start: startTime,
        end: endTime,
      };
      return emitTime;
    },
  },
};
</script>
<style lang="scss" scoped>
.infoMain {
  display: flex;
  align-items: center;
}
.pick {
  margin-right: 5px;
  margin-left: 5px;
}
.now_time {
  display: flex;
  align-items: center;
  height: 19px;
  line-height: 19px;
  .nowtime_text {
    color: #4974f5;
    font-size: 14px;
    margin-left: 10px;
    cursor: pointer;
  }
}
</style>